import React from 'react'
import styled, { ThemeContext } from 'styled-components'
import { ThemeVariants, TypographyTypes } from '../../../constants'
import { DEFAULT_CONFIG } from '../../../constants/styledComponents'
import {
    backgroundValue,
    borderRadiusValue,
    borderValue,
    borderWidthValue,
} from '../../../constants/cssRules'
import Card from '../../Card'
import Typography from '../../Typography'
import Icon from '../../Icon'
import SelectDropdownOption from './SelectDropdownOption'

const StyledDropdown = styled.div.withConfig(DEFAULT_CONFIG)(props => ({
    userSelect: 'none',
    cursor: props.disabled ? 'not-allowed' : 'pointer',
    borderRadius: borderRadiusValue(props),
    border: borderValue(props),
    background: backgroundValue({
        ...props,
        variant: props.disabled ? ThemeVariants.GRAY : props.variant,
    }),
    borderBottomLeftRadius: props.isPanelVisible ? 'unset' : null,
    borderBottomRightRadius: props.isPanelVisible ? 'unset' : null,
    transition: '0.2s all',
    '&:hover': {
        border: props.disabled ? null : borderValue(props, true),
    },
}))

const StyledDropdownPanel = styled.div.withConfig(DEFAULT_CONFIG)(props => {
    const isVisible = props.visible
    const borderWidth = borderWidthValue(props)
    return {
        top: '100%',
        left: `-${borderWidth}`,
        width: `calc(100% + ${borderWidth} + ${borderWidth})`,
        visibility: isVisible ? 'visible' : 'hidden',
        transform: `translateY(${isVisible ? 0 : '-10px'})`,
        opacity: isVisible ? 1 : 0.5,
        zIndex: 1,
        '& .rds-card': {
            borderTopLeftRadius: 'unset',
            borderTopRightRadius: 'unset',
            padding: 'var(--rds-spacingSmall)',
            border: borderValue(props, isVisible),
            borderTop: 'initial',
        },
    }
})

const SelectDropdown = ({
    className,
    values,
    onSelect,
    value,
    disabled,
    placeholder,
}) => {
    const themeContext = React.useContext(ThemeContext)
    const [isPanelVisible, setIsPanelVisible] = React.useState(false)
    const toggleDropdownPanel = () => setIsPanelVisible(!isPanelVisible)
    const current = values.find(option => option.id === value)
    return (
        <StyledDropdown
            className={`rds-form-item_select ${
                disabled ? 'rds-form-item_disabled' : ''
            } ${className || ''} rds-relative`}
            onClick={disabled ? undefined : toggleDropdownPanel}
            themeContext={themeContext}
            disabled={disabled}
            isPanelVisible={isPanelVisible}
        >
            <Icon
                icon='Down'
                className='rds-form-item_select_icon'
                style={{
                    transform: `translateY(-50%) rotate(${
                        isPanelVisible ? 180 : 0
                    }deg)`,
                }}
                width={10}
            />
            <Typography type={TypographyTypes.P}>
                {current ? current.label : placeholder || '-'}
            </Typography>
            <StyledDropdownPanel
                className='rds-absolute'
                visible={isPanelVisible}
                themeContext={themeContext}
            >
                <Card
                    className='rds-p_around__sm rds-m_bottom__md'
                    style={{
                        overflowY: 'auto',
                        maxHeight: 500,
                    }}
                >
                    {values.map((option, index) => {
                        const isActive = value === option.id
                        return (
                            <SelectDropdownOption
                                key={index}
                                isActive={isActive}
                                onSelect={onSelect}
                                option={option}
                            />
                        )
                    })}
                </Card>
            </StyledDropdownPanel>
        </StyledDropdown>
    )
}

export default SelectDropdown
