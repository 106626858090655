import React, { useState, useEffect } from 'react'
import Template from '../layout/Template'
import { ProgressBar, Header, HeaderSizes } from 'rds'

const ProgressBarTemplate = () => {
    const [progress, setProgress] = useState(0)

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress(progress => {
                const newProgress = progress + 1
                if (progress === 100) {
                    return 0
                }
                if (newProgress >= 100) {
                    return 100
                }
                return newProgress
            })
        }, 100)
        return () => clearInterval(interval)
    }, [])

    return (
        <Template
            hashValue='progress-bar'
            componentName='ProgressBar'
            overview={
                <div>
                    <div>
                        <Header
                            title='Showing percentage'
                            text=''
                            size={HeaderSizes.SMALL}
                        />
                        <ProgressBar progress={progress} showPercentage />
                    </div>
                    <div className='rds-m_top__md'>
                        <Header
                            title='Bar only'
                            text=''
                            size={HeaderSizes.SMALL}
                        />
                        <ProgressBar progress={progress} />
                    </div>
                </div>
            }
            example={`
<ProgressBar
    progress={Number}
    text={String}
    showPercentage={Boolean}
/>`}
            properties={[
                {
                    name: 'progress',
                    type: 'NUMBER',
                    description:
                        'Progress percentage. Must be a number between 0 and 100',
                    required: true,
                },
                {
                    name: 'text',
                    type: 'STRING',
                    description:
                        'Text to be shown on the right of the progress bar',
                },
                {
                    name: 'showPercentage',
                    type: 'BOOLEAN',
                    description:
                        'If true, the progress percentage will be shown and a small label too',
                },
            ]}
        />
    )
}

export default ProgressBarTemplate
