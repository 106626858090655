import React from 'react'
import { ThemeContext } from 'styled-components'
import { ThemeVariants, HeaderSizes, ThemesNames } from '../../../constants'
import Tag from '../../Tag'
import Header from '../../Header'
import Card from '../../Card'
import Icon from '../../Icon'

const SelectOption = ({ value, isActive, onSelect, cardsUI, isLastOne }) => {
    const themeContext = React.useContext(ThemeContext)
    if (!cardsUI) {
        return (
            <Tag
                className={isLastOne ? '' : 'rds-m_right__sm'}
                variant={
                    isActive ? ThemeVariants.DARK : ThemeVariants.TRANSPARENT
                }
                onClick={() => onSelect(value.id)}
            >
                {value.label}
            </Tag>
        )
    }
    return (
        <Card
            className={`rds-full-block ${isLastOne ? '' : 'rds-m_right__sm'}`}
            onClick={() => onSelect(value.id)}
            variant={
                isActive && themeContext.scheme === ThemesNames.DARK
                    ? ThemeVariants.DARK
                    : null
            }
            style={
                isActive
                    ? {
                          border: `${themeContext.borderWidth}px ${themeContext.main} solid`,
                      }
                    : undefined
            }
        >
            {isActive ? (
                <Icon
                    className='rds-absolute'
                    icon='CheckOne'
                    theme='filled'
                    size={20}
                    variant={ThemeVariants.MAIN}
                    style={{ top: 5, right: 5 }}
                />
            ) : null}
            <Header
                title={value.label}
                text={value.description}
                size={HeaderSizes.XSMALL}
            />
        </Card>
    )
}

export default SelectOption
