import React from 'react'
import { ThemeVariants } from '../../../constants'
import Block from '../../Block'
import ComponentError from '../../States/ComponentError'
import SelectCardOption from './SelectCardOption'
import SelectDropdown from './SelectDropdown'

const Select = ({
    id,
    className,
    values,
    value,
    currentValue,
    onSelect,
    cardsUI,
    placeholder,
    disabled,
    forceDropdown,
}) => {
    const [safeValue, setSafeValue] = React.useState(currentValue)
    const columns = values.length > 3 ? 3 : values.length

    React.useEffect(() => {
        setSafeValue(value)
    }, [value])

    if (!values) {
        return (
            <ComponentError
                componentName='Select'
                errorMessage='A "values" prop is needed'
            />
        )
    }
    if (forceDropdown || (values.length > 2 && !cardsUI)) {
        return (
            <SelectDropdown
                className={className}
                values={values}
                onSelect={onSelect}
                value={value}
                placeholder={placeholder}
                disabled={disabled}
            />
        )
    }
    return (
        <Block
            roundedBorder
            bordered
            id={id}
            className={`rds-form-select ${
                cardsUI
                    ? `rds-form-select_cards rds-full-block rds-grid rds-grid_${columns}-columns`
                    : ''
            } ${className || ''}`}
            variant={ThemeVariants.GRAY}
        >
            {values.map((value, index) => {
                const isActive =
                    safeValue !== null &&
                    safeValue !== undefined &&
                    safeValue.toString() === value.id.toString()
                return (
                    <SelectCardOption
                        key={index}
                        value={value}
                        isActive={isActive}
                        onSelect={onSelect}
                        cardsUI={cardsUI}
                        isLastOne={index === values.length - 1}
                    />
                )
            })}
        </Block>
    )
}

export default Select
