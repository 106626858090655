import React from 'react'
import styled, { ThemeContext } from 'styled-components'
import { ThemesNames, ThemeVariants, TypographyTypes } from '../../../constants'
import { borderRadiusValue, getCSSVariable } from '../../../constants/cssRules'
import { DEFAULT_CONFIG } from '../../../constants/styledComponents'
import Typography from '../../Typography'

const StyledDropdownPanelOption = styled.div.withConfig(DEFAULT_CONFIG)(
    props => ({
        cursor: 'pointer',
        ['& .rds-form-item_select_option-label span, & .rds-form-item_select_option-label b']:
            {
                position: 'relative',
                zIndex: 1,
            },
        '& .rds-form-item_select_option-label:before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: borderRadiusValue(props),
            backgroundColor: props.isActive
                ? props.themeContext.main
                : 'transparent',
        },
        '& .rds-form-item_select_option-label:hover:not(.rds-form-item_select_option-label_active):before':
            {
                backgroundColor: getCSSVariable(
                    props.themeContext.scheme === ThemesNames.LIGHT
                        ? 'colorGrayLight'
                        : 'colorGrayDark',
                ),
            },
    }),
)

const SelectDropdownOption = ({ onSelect, option, isActive }) => {
    const themeContext = React.useContext(ThemeContext)
    return (
        <StyledDropdownPanelOption
            onClick={() => onSelect(option.id)}
            themeContext={themeContext}
            className='rds-relative'
            isActive={isActive}
        >
            <Typography
                className={`rds-form-item_select_option-label ${
                    isActive ? 'rds-form-item_select_option-label_active' : ''
                } rds-p_top__sm rds-p_bottom__sm rds-p_left__sm rds-p_right__sm`}
                type={TypographyTypes.P}
                variant={isActive ? ThemeVariants.WHITE : undefined}
            >
                {isActive ? <b>{option.label}</b> : <span>{option.label}</span>}
            </Typography>
        </StyledDropdownPanelOption>
    )
}

export default SelectDropdownOption
