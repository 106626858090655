import React from 'react'
import { Header, HeaderSizes, Tag, ThemeVariants, Typography } from 'rds'

const ChangelogItem = ({ item }) => {
    const { version, changes } = item
    return (
        <article>
            <Header title={version} size={HeaderSizes.MEDIUM} />
            {changes.map((change, index) => (
                <ul key={index}>
                    <li>
                        <Typography>
                            <b>{change.title}</b>
                        </Typography>
                        {change.description?.map((desc, index) => (
                            <Typography key={index}>{desc}</Typography>
                        ))}
                        <ul className='rds-m_top__sm'>
                            {change.updates?.map((update, index) => (
                                <li key={index}>
                                    <Typography>
                                        <Tag
                                            className='rds-m_right__sm'
                                            variant={ThemeVariants.DASHED}
                                        >
                                            {update.from}
                                        </Tag>
                                        →{' '}
                                        <Tag className='rds-m_left__sm'>
                                            {update.to}
                                        </Tag>
                                    </Typography>
                                </li>
                            ))}
                        </ul>
                    </li>
                </ul>
            ))}
        </article>
    )
}

export default ChangelogItem
