import React from 'react'
import { ButtonVariants } from '../../constants'
import ButtonGroup from '../Button/ButtonGroup'
import Button from '../Button'

const FormActions = ({
    isSubmitting,
    secondaryActions,
    submitLabel = 'Submit',
    submitCallback,
}) => {
    return (
        <div className='rds-form_actions rds-m_top__sm rds-full-block rds-flex rds-align-center rds-justify-center rds-text-align_right'>
            {secondaryActions ? (
                <ButtonGroup className='rds-form_btn-group'>
                    {secondaryActions.map(action => (
                        <Button
                            key={action.label}
                            variant={action.buttonVariant}
                            onClick={action.callback}
                            label={action.label}
                            icon={action.buttonIcon}
                            disabled={isSubmitting}
                        />
                    ))}
                </ButtonGroup>
            ) : null}
            <Button
                variant={ButtonVariants.MAIN}
                label={submitLabel}
                onClick={submitCallback}
                disabled={isSubmitting}
            />
        </div>
    )
}

export default React.memo(FormActions)
