import React from 'react'
import { Tag, Typography } from 'rds'

export default [
    {
        version: '0.27.2',
        changes: [
            {
                title: 'Improve Form component UI/UX',
                description: ['General UI fixes on Form loading state'],
            },
            {
                title: 'Documentation updates',
                description: ['Added new examples and fixed some typos'],
            },
        ],
    },
    {
        version: '0.27.1',
        changes: [
            {
                title: 'Improve Select component responsiveness',
                description: [
                    'The columns number now automatically change depending on the screen size',
                    'The maximum number of columns is now 3',
                ],
            },
            {
                title: 'Improve Form component UI/UX',
                description: [
                    'Loading state was updated to be more user-friendly',
                ],
            },
        ],
    },
    {
        version: '0.27.0',
        changes: [
            {
                title: 'Standardize input background colors',
                description: [
                    'Unify the background color of the inputs to make them more consistent',
                ],
            },
            {
                title: 'Deprecate "inputBackgroundRule" CSS rule',
                description: [
                    'Removed the "inputBackgroundRule" CSS rule from the library',
                ],
            },
        ],
    },
    {
        version: '0.26.3',
        changes: [
            {
                title: 'Updated CodeBlock component',
                description: [
                    'New props were added to the CodeBlock component',
                ],
                updates: [
                    {
                        from: '',
                        to: 'language',
                    },
                ],
            },
        ],
    },
    {
        version: '0.26.2',
        changes: [
            {
                title: 'Added new CSS classes',
                description: ['Some positioning and sizing classes were added'],
                updates: [
                    {
                        from: '',
                        to: 'rds-width_auto',
                    },
                    {
                        from: '',
                        to: 'rds-height_auto',
                    },
                ],
            },
        ],
    },
    {
        version: '0.26.1',
        changes: [
            {
                title: 'Fixes in CSS classes',
                description: [
                    <Typography key={0}>
                        There were some CSS classes were not named correctly,
                        and others did not contain the <Tag>rds-</Tag> prefix.
                    </Typography>,
                ],
                updates: [
                    {
                        from: 'rds-flexbox',
                        to: 'rds-flex',
                    },
                    {
                        from: 'align-center',
                        to: 'rds-align-center',
                    },
                    {
                        from: 'align-start',
                        to: 'rds-align-start',
                    },
                    {
                        from: 'align-bottom',
                        to: 'rds-align-bottom',
                    },
                    {
                        from: 'align-end',
                        to: 'rds-align-end',
                    },
                    {
                        from: 'justify-between',
                        to: 'rds-justify-between',
                    },
                    {
                        from: 'justify-center',
                        to: 'rds-justify-center',
                    },
                    {
                        from: 'justify-evenly',
                        to: 'rds-justify-evenly',
                    },
                    {
                        from: 'justify-spread',
                        to: 'rds-justify-spread',
                    },
                ],
            },
        ],
    },
]
